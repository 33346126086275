// @flow

export const appEnvironment = 'pre-production';

export const API_URL = 'https://ms-api-dot-pprd-minchem-solvextract.appspot.com';
export const API_VER = '/api/';

export const firebaseConfig = {
    apiKey: 'AIzaSyBDcrIHFtBV2cP_J8GWgwuvPvuuy7ZoHUM',
    authDomain: 'pprd-minchem-solvextract.firebaseapp.com',
    projectId: 'pprd-minchem-solvextract',
};

export const gaTrackingId = '';

// Used to replace Login UI, for application blocking (must contain content to block application)
export const appBlockingMessage = '';

export const MicroFrontEnds = {
    reportGenerationSystem: 'https://rgs-fe-dot-pprd-minchem-solvextract.appspot.com',
    plantConfiguration: 'https://plant-configuration-mfe-dot-pprd-minchem-solvextract.appspot.com',
    platformStatus: 'https://platform-status-mfe-dot-pprd-minchem-solvextract.appspot.com',
    plantDashboard: 'https://plant-dashboard-mfe-dot-pprd-minchem-solvextract.appspot.com',
    cyanex: 'https://cyanex-mfe-dot-pprd-minchem-solvextract.appspot.com',
    reagentsManagement: 'https://reagents-management-mfe-dot-pprd-minchem-solvextract.appspot.com',
    minChem: 'https://minchem-frontend-mfe-dot-pprd-minchem-solvextract.appspot.com'
};
export const NAVBAR_DASHBOARD_V2_REDIRECT = true;

export const PIWIK_PRO_TRACKERID = '';

export const PIWIK_PRO_API_URL = '';